import React, { Component } from 'react';
import './Slides.css';

class Slides extends Component {
  
  constructor (props) {
    super(props);

    let myList = props.list ? props.list.reverse() : [];

    this.state =  {
                    list : myList, 
                    active : myList.length - 1, 
                    max:myList.length - 1,
                    wrapperHeight:0
                  };
    
  }

  heightSet = (e) => {

    this.imgHandle = e.target;

    let temp = this.state;

    if (!temp.wrapperHeight) {
      temp.wrapperHeight = e.target.height; 
      this.setState(temp); 
    }
  }

  resizeSet = (e) => {

    let temp = this.state;
    
    temp.wrapperHeight = this.imgHandle.height; 
    this.setState(temp);    
  }

  componentDidMount () {
    
    if (!this.state.list.length) {
      return;
    }

    if (this.state.max) {

      this.hInterval = setInterval(() => {

        let temp = this.state;
        temp.active--;

        if (temp.active  < 0) {
          temp.active = temp.max;
        }

        this.setState(temp);
      }, 
      10000);
    }

    window.addEventListener('resize', this.resizeSet);
  }

  componentWillUnmount () {
    clearInterval(this.hInterval);
    window.removeEventListener('resize', this.resizeSet);
  }


  render () {

    if (!this.state.list.length) {
      return null;
    }

    return (
      <div className='slide-wrapper' style={{height:this.state.wrapperHeight + 'px'}}>

      {this.state.list.map( (el, i) => {

          const myStyle = {zIndex:i};
          let   myClass = 'slide-child slide-hidden';

          if (this.state.active !== this.state.max) {
            if (this.state.active === i) { 
              myClass = 'slide-child slide-shown';
            }  
          }
          else {
            if (this.state.active === i) {
              myClass = 'slide-child slide-fade-top';
            }
            else if (i === 0) {
              myClass = 'slide-child slide-shown';
            } 
            else {
              myClass = 'slide-child slide-hidden';
            }
          }

          return (<img key={i} src={el} alt='' style={myStyle} className={myClass} onLoad={this.heightSet} />);
        })
      }
      </div>
    )
  }
}

export default Slides;
