import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Slides from './Slides';
import './App.css';
import projects from './projects.json';
import $ from 'jquery';

class App extends Component {
  
  constructor (props) {
    super(props);

    this.state = {'bgImage':projects[0].images[0]};
  }

  componentDidMount () {
    /*
    fetch('https://hn.algolia.com/api/v1/search?query=redux')
      .then(response => response.json())
      .then(data => {
        this.setState({hits : data.hits});
        // console.log('Logging on mount and fetch');
        // console.log(this.state.data);
    });*/
  }

  doClose = () => {

      
    //if ($(window).width() < 650) {
  
      // $('body, html').animate({'scrollTop': 0}, 300, () => {
      //   window.location.href = "https://apdev.uk/work";
      // });
      // return;
    //}
    console.log('doClose');

    $('body, html').animate({'scrollTop': 0}, 300, () => {
      let myState = this.state;        

      myState.navigate = true;
      this.setState(myState);
    });

    setTimeout( () => {
      let myState      = this.state;  
      myState.navigate = false;
      this.setState(myState);
    },
    400);
  }

  workHover = (event) => {

    let myId = event.target.id;

    if (!myId) {
      myId = event.target.parentElement.id;
    }

    const found = projects.find((element) => {
      return myId === element.id;  
    }); 
    
    if (found.images[0]) {
      this.setState({'bgImage':found.images[0]});
    }
  }

  Project = ({ match }) => {

    let found = projects.find((element) => {
      return match.params.name === element.id;  
    }); 

    if (found.images[0] !== this.state.bgImage) { 
      this.setState({'bgImage':found.images[0]});  
    }

    let target = Math.round(0.55*$(window).height()) + 'px';

    console.log('animoot');
    $('body, html').stop().animate({'scrollTop':target}, 300, () => {    
    });

    return (
      <div id="contentwrapper">
      <p id="close" onClick={this.doClose}>&laquo; Back</p>
      <div id="content">
        <h1>{found.name}</h1>
        <div className="leftcol">
          <Slides list={found.slides}/>
          {found.body.map((o, i) => {
            
            if (typeof(o) === 'object') {
              if (o.vimeo) {
                return(
                  <div key={i} style={{padding:'56.25% 0 0 0', position:'relative', margin:'2.5em 0 1em 0'}}>
                  <iframe title="video" src={o.vimeo} frameBorder='0' allow="autoplay; fullscreen" allowFullScreen style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}></iframe>
                  </div>
                );    
              }
              if (o.image) {
                return(<img key={i} src={o.image} alt={o.alt} />);  
              }
              if (o.full) {
                let innerBody = {__html:o.full};
                return (<p class="full" key={i} dangerouslySetInnerHTML={innerBody}></p>);  
              }
            }

            let innerBody = {__html:o};
            return (<p key={i} dangerouslySetInnerHTML={innerBody}></p>);
          }
          )}
        </div>
        <div className='rightcol'>
        {found.rightcol.map((o, indexOuter) => {
          
          if (o.image) {
            return (<img key={indexOuter} src={o.image} alt={o.alt} />);
          }

          return (
            <div key={indexOuter}>  
              <h2>{o.heading}</h2>
              <ul>
                {o.list.map((l, index) => {

                  if (typeof l === 'string') {
                    return (<li key={index}>{l}</li>);
                  }
                  
                  return (<li key={index}><a href={l.link} target="_blank" rel="noopener noreferrer">{l.text}</a></li>);
                  
                })}
              </ul>
            </div>     
          );

        })}
        </div>
        
        <p to="/work" id="back" onClick={this.doClose}> &laquo; Back to projects</p>
      </div>
      <p id="copy">Copyright &copy; 2024 Nine Tails Arts Limited. All rights reserved.</p>
    
    </div>
    )
  }

  Work = ({ match }) => {

    return (
      <div>
        <div id="leftmenu">
          {
          <ul id="leftinner" className={match.isExact ? 'menuShown' : 'menuHidden'}>
            {projects.map( ({ name, id, client, nomenu }) => {
              
              if (nomenu) {
                return null;
              }

              return (
                <li key={id}>
                  <Link to={`${match.url}/${id}`} onMouseEnter={this.workHover} id={id}>{name} <span>({client})</span></Link>
                </li>
              )
            })}
          </ul>
          } 
        </div>
      {<Route path={`${match.path}/:name`} component={this.Project}/>}
      </div>
    );
  }

  Child = ({ match }) => {
    return (
      <div>
        <div id="leftmenu"></div>
        {<Route path={`/:name`} component={this.Project}/>}
      </div>
    );
  }

  Redirect = ({match}) => {    
    return (
      <Redirect to='/work' />
    );
  }

  render () {

    if (this.state.navigate) {
      console.log('Redirecting');
      console.log('this.state.navigate ' + this.state.navigate);
    }
 
    return (
      <Router>
        {this.state.navigate ? (<Redirect to='/work' push={true} />) : null}
        
        <div id="titlewrapper">
          <h2 id="title" >NINE TAILS <span>Arts</span></h2>  
          <div id="menu">
            <ul>
              <li id="first"><Link to="/work" onClick={this.doClose}>Work</Link></li>
              <li><Link to="/bio">History</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
        </div> 

        <Route exact path='/' component={this.Redirect} />
        <Route path='/work' component={this.Work} />
        <Route path='/bio' component={this.Child} />
        <Route path='/contact' component={this.Child} />

        <div id="projectbg" className="project" style={{backgroundImage: `url(${this.state.bgImage})`}}></div>
      </Router>
    );
  }
}

export default App;
